import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { EyeIcon, EyeOffIcon, FingerPrintIcon, CheckIcon } from '@heroicons/react/solid'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import Loading from '@/shared/Loading'

import Card from '@/shared/Card'
import Modal from '@/shared/Modal'
import { useGlobalState } from '@/state'
import { randomToastSuccess } from '@/shared/Toast'

import PublicSubmissionRow from '@/pages/Users/PublicProfile/PublicSubmissionRow'
import useQuery from '@/hooks/useQuery'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

const PreviewForm = ({ submission }) => {
  const [, setToast] = useGlobalState('toast')
  const [token, setToken] = useState(null)
  const [renderingVideo, setRenderingVideo] = useState(false)
  const { putpostRequest, getRequest } = useQuery()
  const pollRef = useRef(0)
  console.log(submission)

  getRequest(`/api/v3/auditions/${submission.id}/videomancy_preview_form`, {}, (err, jsonData) => {
    if (err) { /* hook */ }
    setToken(jsonData.token)
  })

  const renderVideo = debounce(300, (data) => {
    putpostRequest(`/api/v3/auditions/${submission.id}/render_videomancy_video`, 'POST', {}, (err, jsonData) => {
      if (err) { return console.log(err) }

      setRenderingVideo(true)
      setTimeout(poll, 3000)
    })
  })

  const poll = () => {
    pollRef.current += 1
    getRequest(`/api/v3/auditions/${submission.id}`, {}, (err, jsonData) => {
      if (err) { return }
      if (pollRef.current > 100) {
        setToast('Code 241: Could not render video. I will call the police. This is not right.')
        return
      }
      if (jsonData.submission.videomancyStatus === 'render_finished') {
        window.location.reload()
      } else {
        setTimeout(poll, 3000)
      }
    })
  }


  if (!token) return <Loading />

  if (renderingVideo) {
    return <div className='flex flex-col gap-y-2 items-center max-w-96 mx-auto justify-center bg-cccblue bg-opacity-10 p-4 rounded-2xl'>
      <Loading noMessage noLoadingMessage />
      <div className='text-sm'>Rendering video now. This usually takes about 3 minutes.</div>
      <div className='text-sm'>Jeopardy music.. 🎵</div>
      <div className='text-sm'>You can safely leave the page if you want. Or you can wait here. Either way just know that I hope you're doing great.</div>
    </div>
  }

  return <div className='flex flex-col gap-y-2 items-center mx-auto w-full justify-center bg-cccblue bg-opacity-10 p-4 rounded-2xl'>
    <iframe src={`https://www.videomancy.com/videos/${submission.videomancyUuid}/preview?show_editor=true&allowed_dimensions=1920x1080&token=${token}`} width="100%" height="700" frameBorder="0" allowFullScreen></iframe>
    <div className='flex gap-x-4 justify-center items-center mt-2'>
      <button onClick={renderVideo} className="flex justify-center space-x-2 items-center px-8 py-4 shadow-sm text-sm font-medium rounded-md text-white bg-cccblue hover:text-white hover:bg-cccblue-alt dark:hover:bg-cccblue-alt dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">Render Video!</button>
    </div>
  </div>
}

const VideomancyPrompt = ({initialSubmission}) => {
  const [, setToast] = useGlobalState('toast')
  const [currentUser] = useGlobalState('currentUser')
  const [template, setTemplate] = useState(null)
  const [makingVideo, setMakingVideo] = useState(false)
  const [makingCustomVideo, setMakingCustomVideo] = useState(false)
  const [submission, setSubmission] = useState(initialSubmission)
  const [loading, setLoading] = useState(false)
  const { putpostRequest, getRequest } = useQuery()
  const pollRef = useRef(0)

  if (!currentUser) return null
  if (currentUser.id !== submission.userId) return null

  const goToSubmissionShow = () => {
    window.location.href = `/submissions/${submission.id}`
  }

  const makeVideo = debounce(300, (data) => {
    setMakingVideo(true)
    putpostRequest(`/api/v3/auditions/${submission.id}/create_videomancy_video`, 'POST', {template: template}, (err, jsonData) => {
      if (err) { return console.log(err) }

    })
  })

  const makeCustomVideo = debounce(300, (data) => {
    if (!currentUser.canMakeVideomancyVideos) {
      return setToast('This feature is only available to CCC Premium members.')
    }
    setMakingCustomVideo(true)
    putpostRequest(`/api/v3/auditions/${submission.id}/create_videomancy_video`, 'POST', {template: template}, (err, jsonData) => {
      if (err) { return console.log(err) }

      setTimeout(poll, 3000)
    })
  })

  const poll = () => {
    pollRef.current += 1
    getRequest(`/api/v3/auditions/${submission.id}`, {}, (err, jsonData) => {
      if (err) { return }
      if (pollRef.current > 20) {
        setToast('Code 239: Could not create video. Are you sure this submission has spoken words?')
        return
      }
      if (jsonData.submission.videomancyStatus === 'captions_finished') {
        setSubmission(jsonData.submission)
      } else {
        setTimeout(poll, 3000)
      }
    })
  }

  if (submission.kind !== 'Voice Actor' && submission.kind !== 'Singer') return null

  if (submission.videomancyStatus === 'captions_finished') {
    return <PreviewForm submission={submission} />
  }
  if (submission.videoUrl) {
    return <ul className='max-w-xl mx-auto'><PublicSubmissionRow submission={submission} voteIds={[]} commentsOpen={false} image='user' /></ul>
  }

  if (makingVideo) {
    return <div className='flex flex-col gap-y-2 items-center max-w-xl mx-auto justify-center bg-cccblue bg-opacity-10 p-4 rounded-2xl'>
      <Loading noMessage noLoadingMessage />
      <div className='text-sm'>Creating your video... This takes about 5 minutes. You can safely leave the page and do other things. We will notify you when it's done.</div>
    </div>
  }

  if (makingCustomVideo) {
    return <div className='flex flex-col gap-y-2 items-center max-w-xl mx-auto justify-center bg-cccblue bg-opacity-10 p-4 rounded-2xl'>
      <Loading noMessage noLoadingMessage />
      <div className='text-sm'>Creating your preview... This takes about 1 minute. Please stay here</div>
    </div>
  }

  if (template) {
    return <div className='flex flex-col gap-y-2 items-center max-w-2xl mx-auto justify-center bg-cccblue bg-opacity-10 p-4 rounded-2xl'>
      { template === 'audio' && <>All done! Your audio submission is ready. You can hear it above.</> }
      { template === 'custom' && <>
        <div className="bg-cccblue dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded-lg border-2 border-black min-w-96">
          <div className="relative rounded-t-md overflow-hidden w-full aspect-w-16 aspect-h-9">
            <img src="https://images.videomancy.com/template11-d72a3daf.gif" className="w-full h-full absolute left-0 top-0" />
          </div>
        </div>
      </> }
      { template === 'lightspeed' && <>
        <div className="bg-cccblue dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded-lg border-2 border-black min-w-96">
          <div className="relative rounded-t-md overflow-hidden w-full aspect-w-16 aspect-h-9">
            <img src="https://images.videomancy.com/template10-687c498a.gif" className="w-full h-full absolute left-0 top-0" />
          </div>
        </div>
      </> }
      { template === 'forest' && <>
        <div className="bg-cccblue dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded-lg border-2 border-black min-w-96">
          <div className="relative rounded-t-md overflow-hidden w-full aspect-w-16 aspect-h-9">
            <img src="https://images.videomancy.com/template9-b861bac6.gif" className="w-full h-full absolute left-0 top-0" />
          </div>
        </div>
      </> }
      { template === 'cloudy' && <>
        <div className="bg-cccblue dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded-lg border-2 border-black min-w-96">
          <div className="relative rounded-t-md overflow-hidden w-full aspect-w-16 aspect-h-9">
            <img src="https://images.videomancy.com/template1-c857dc16.gif" className="w-full h-full absolute left-0 top-0" />
          </div>
        </div>
      </> }
      { template === 'amethyst' && <>
        <div className="bg-cccblue dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded-lg border-2 border-black min-w-96">
          <div className="relative rounded-t-md overflow-hidden w-full aspect-w-16 aspect-h-9">
            <img src="https://images.videomancy.com/template2-dfc91fb6.gif" className="w-full h-full absolute left-0 top-0" />
          </div>
        </div>
      </> }
      { template !== 'audio' && !currentUser.canMakeVideomancyVideos && <div className="rounded-md bg-cccpurple dark:bg-cccorange bg-opacity-20 dark:bg-opacity-20 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-cccpurple dark:text-cccorange" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-cccpurple dark:text-cccorange">You will not be able to edit the captions if there are any spelling mistakes unless you choose the 'custom' template.</p>
          </div>
        </div>
      </div> }
      <span>Change backgrounds, colors, animations, inclusion of title and avatar. You name it.</span>
      <div className='flex gap-x-2'>
        { template !== 'audio' && template !== 'custom' && <PrimaryButton onClick={currentUser.canMakeVideomancyVideos ? makeCustomVideo : makeVideo} text="Make Video" /> }
        { template === 'custom' && <PrimaryButton onClick={makeCustomVideo} text="Make Video" /> }
        <DefaultButton onClick={() => setTemplate(null)} text="Choose a different style" />
      </div>
    </div>
  }

  return <div className='flex flex-col gap-y-2 items-center max-w-2xl mx-auto justify-center bg-cccblue bg-opacity-10 p-4 rounded-2xl'>
    <h3 className='text-2xl font-bold text-center'>Submission Style</h3>
    <div className='text-xs text-center'>(optional)</div>
    <div className='grid grid-cols-2 sm:grid-cols-3 gap-2 items-center justify-center'>
      <div onClick={() => setTemplate('audio')} className="bg-cccblue dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded-lg hover:bg-opacity-20 border-2 border-black cursor-pointer">
        <div className="relative rounded-t-md overflow-hidden w-full aspect-w-16 aspect-h-9">
          <img src="https://images.videomancy.com/audio-ccc.gif" className="w-full h-full absolute left-0 top-0" />
        </div>
        <div className="p-2 dark:text-white flex flex-col">
          <div className="">Audio Only</div>
          <div className="text-xs">Normal waveform that you see everywhere on CCC</div>
        </div>
      </div>
      <div onClick={() => setTemplate('amethyst')} className="bg-cccblue dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded-lg hover:bg-opacity-20 border-2 border-black cursor-pointer">
        <div className="relative rounded-t-md overflow-hidden w-full aspect-w-16 aspect-h-9">
          <img src="https://images.videomancy.com/template2-dfc91fb6.gif" className="w-full h-full absolute left-0 top-0" />
        </div>
        <div className="p-2 dark:text-white flex flex-col">
          <div className="">Video (Amethyst)</div>
          <div className="text-xs">Animated captions with avatar.</div>
        </div>
      </div>
      <div onClick={() => setTemplate('cloudy')} className="bg-cccblue dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded-lg hover:bg-opacity-20 border-2 border-black cursor-pointer">
        <div className="relative rounded-t-md overflow-hidden w-full aspect-w-16 aspect-h-9">
          <img src="https://images.videomancy.com/template1-c857dc16.gif" className="w-full h-full absolute left-0 top-0" />
        </div>
        <div className="p-2 dark:text-white flex flex-col">
          <div className="">Video (Cloudy)</div>
          <div className="text-xs">Comic background. Includes avatar. Animated captions.</div>
        </div>
      </div>
      <div onClick={() => setTemplate('forest')} className="bg-cccblue dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded-lg hover:bg-opacity-20 border-2 border-black cursor-pointer">
        <div className="relative rounded-t-md overflow-hidden w-full aspect-w-16 aspect-h-9">
          <img src="https://images.videomancy.com/template9-b861bac6.gif" className="w-full h-full absolute left-0 top-0" />
        </div>
        <div className="p-2 dark:text-white flex flex-col">
          <div className="">Video (Forest)</div>
          <div className="text-xs">Relaxing vibe. No avatar. I'm going to make a tea.</div>
        </div>
      </div>
      <div onClick={() => setTemplate('lightspeed')} className="bg-cccblue dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded-lg hover:bg-opacity-20 border-2 border-black cursor-pointer">
        <div className="relative rounded-t-md overflow-hidden w-full aspect-w-16 aspect-h-9">
          <img src="https://images.videomancy.com/template10-687c498a.gif" className="w-full h-full absolute left-0 top-0" />
        </div>
        <div className="p-2 dark:text-white flex flex-col">
          <div className="">Video (Lightspeed)</div>
          <div className="text-xs">One word at a time. No avatar. Lowercase.</div>
        </div>
      </div>
      <div onClick={() => setTemplate('custom')} className="bg-cccblue dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded-lg hover:bg-opacity-20 border-2 border-black cursor-pointer">
        <div className="relative rounded-t-md overflow-hidden w-full aspect-w-16 aspect-h-9">
          <img src="https://images.videomancy.com/template11-d72a3daf.gif" className="w-full h-full absolute left-0 top-0" />
        </div>
        <div className="p-2 dark:text-white flex flex-col">
          <div className="">Custom</div>
          <div className="text-xs flex flex-col"><span>Design your own. Change backgrounds, edit captions, you name it.</span> {!currentUser.canMakeVideomancyVideos && <span className='inline-flex justify-center px-2 shadow-sm text-xs text-white font-medium rounded-md bg-gradient-to-r from-cccpurple via-cccblue to-cccorange hover:from-cccpurple hover:to-cccorange hover:text-white'>Premium Only</span> }</div>
        </div>
      </div>
    </div>

    <div className="rounded-md bg-cccpurple dark:bg-cccorange bg-opacity-20 dark:bg-opacity-20 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg className="h-5 w-5 text-cccpurple dark:text-cccorange" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-cccpurple dark:text-cccorange">No matter what style you choose, the project owner can listen to your original <b><i>uncompressed</i></b> raw audio file at any time.</p>
        </div>
      </div>
    </div>
    <div className='text-gray-700 dark:text-gray-200'>
      <span>Want to create animated captions for ANY audio or video file? Try Buford's new tool</span> <a href="https://www.videomancy.com" target="_blank" rel="noopener noreferrer" className='text-cccpurple dark:text-cccorange underline'>Videomancy</a>!
    </div>
  </div>
}

export default VideomancyPrompt

VideomancyPrompt.propTypes = {
  initialSubmission: PropTypes.object.isRequired
}
